import { userExtraServiceConf } from '@/constant/config.js';
import axios from "axios";
const queryString = require('query-string');
export default {
    getElementsList(requestParam) {
        let queryParams = queryString.stringify(requestParam);
        return axios({
            method: "GET",
            url: `${userExtraServiceConf.listExtraServices}?${queryParams}`,
            withCredentials: true,
        });
    },
    getExtraServices(id) {
        return axios({
            method: "GET",
            url: `${userExtraServiceConf.getExtraServices.format(id)}`,
            withCredentials: true,
        });
    },
    markedDone(id) {
        return axios({
            method: "GET",
            url: `${userExtraServiceConf.markedDone.format(id)}`,
            withCredentials: true,
        });
    },
    markedDenied(id) {
        return axios({
            method: "GET",
            url: `${userExtraServiceConf.markedDenied.format(id)}`,
            withCredentials: true,
        });
    },
};
