import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import userExtraService from '@/api/common/userExtraService';
import lazyLoadComponent from "@/scripts/lazyLoadComponent";
import SkeletonBoxWithoutLoading from "@/components/SkeletonBoxWithoutLoading";
import { debounce } from 'vue-debounce';
import { mapState } from "vuex";
export default {
    extends: baseComponent,
    components: {
        ColumnBalance: lazyLoadComponent({
            componentFactory: () => import("@/views/components/column/Balance.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceType_1 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/components/ExpandImageThreshold.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceType_2 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/components/ExpandStorageThreshold.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceType_3 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/components/OpenPort.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceType_4 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/components/General.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceType_5 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/components/General.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceType_6 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/components/General.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceType_7: lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/components/General.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceType_8: lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/components/General.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),

        //=======================================
        extraServiceDetailType_1 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/detailComponents/ExpandImageThreshold.vue"),
            loading: SkeletonBoxWithoutLoading, 
        }),
        extraServiceDetailType_2 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/detailComponents/ExpandStorageThreshold.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceDetailType_3 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/detailComponents/OpenPort.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceDetailType_4 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/detailComponents/nvLink.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceDetailType_5 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/detailComponents/General.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceDetailType_6 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/detailComponents/General.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceDetailType_7: lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/detailComponents/General.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceDetailType_8: lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/detailComponents/General.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
    },
    data() {
        return {
            debounceFnc: null,
            elements: {},
            detailModel: null,
            packageInfoDictionary: {},
            requestParam: {
                querySearch: '',
                orderBy: 'CREATED_AT',
                directionSort: 'ASC',
                pageIndex: 1,
                pageSize: 20,
                outRowsNumber: 0,
            },
            isLoadingList: false
        };
    },

    computed: {
        ...mapState({
            listStaff: state => state.staffs,
            listServicePack: (state) => state.common.listServicePack,
        }),
    },

    created() {
        this.getData(this.requestParam);
        this.debounceFnc = debounce(() => {
            this.getData(this.requestParam);
        }, 1000);
    },

    methods: {
        // Action
        performAction(param) {
            switch (param.action) {
                case 'refreshList':
                    this.getData(this.requestParam);
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
            }
        },

        // Get data
        getData(request) {
            this.isLoadingList = true;
            userExtraService.getElementsList(request)
                .then((res) => {
                    try {
                        let responseData = res.data.data;
                        let pagingItem = res.data.data.pagingItem;
                        this.elements = responseData.data;
                        this.requestParam.orderBy = pagingItem.orderBy;
                        this.requestParam.directionSort = pagingItem.directionSort;
                        this.requestParam.pageIndex = pagingItem.pageIndex;
                        this.requestParam.pageSize = pagingItem.pageSize;
                        this.requestParam.outRowsNumber = pagingItem.outRowsNumber;
                    } catch (error) {
                        console.error("getData -> error", error);
                    }
                    setTimeout(() => {
                        this.isLoadingList = false;
                    }, 500);
                })
                .catch(error => {
                    console.error("getData -> error", error);
                    this.isLoadingList = false;
                });
        },

        // Pagination size change
        handleSizeChange(pageSize) {
            let request = {
                orderBy: this.requestParam.orderBy,
                directionSort: this.requestParam.directionSort,
                pageIndex: 1,
                pageSize: pageSize,
                outRowsNumber: this.requestParam.outRowsNumber,
            };
            this.getData(request);
        },
        getPackageInfo(elementServicePackage) {
            if (this.packageInfoDictionary.hasOwnProperty(elementServicePackage)) return this.packageInfoDictionary[elementServicePackage];

            let packageInfo = this.listServicePack.find(x => x.id.toLowerCase() === elementServicePackage.toLowerCase());
            if (packageInfo && packageInfo !== null) {
                this.packageInfoDictionary[elementServicePackage] = packageInfo;
                return packageInfo;
            }
            else {
                //console.log("elementServicePackage", elementServicePackage);
                return {
                    name: "---",
                    description: "---",
                    serviceType: 0,
                    numberCard: "---"
                }
            }
        },
        // Pagination page change
        handleCurrentChange(pageIndex) {
            let request = {
                orderBy: this.requestParam.orderBy,
                directionSort: this.requestParam.directionSort,
                pageIndex: pageIndex,
                pageSize: this.requestParam.pageSize,
                outRowsNumber: this.requestParam.outRowsNumber,
            };
            this.getData(request);
        },
        markCompleted(targetItem) {
            commonAct.showConfirm(`Marked this request has Completed: 
                    <div><strong>${targetItem.extraServiceName}</strong> on machine <strong>${targetItem.vmName} (${targetItem.vmMachineId})</strong> ?</div>`, () => {
                this.showLoading();
                userExtraService.markedDone(targetItem.id).then(response => {
                    if (response.data.result === 0) {
                        console.log(response.data);
                        targetItem.status = response.data.data.status;
                        targetItem.statusText = response.data.data.statusText;
                        targetItem.statusClass = response.data.data.statusClass;
                    } else {
                        commonAct.showError(response.data.message);
                    }
                    this.hideLoading();
                }).catch(error => {
                    console.error(error);
                    commonAct.showError(error);
                    this.hideLoading();
                });
            });
        },
        markReject(targetItem) {
            commonAct.showConfirm(`Marked this request has been Rejected: 
                    <div><strong>${targetItem.extraServiceName}</strong> on machine <strong>${targetItem.vmName} (${targetItem.vmMachineId})</strong> ?</div>`, () => {
                this.showLoading();
                userExtraService.markedDenied(targetItem.id).then(response => {
                    if (response.data.result === 0) {
                        console.log(response.data);
                        targetItem.status = response.data.data.status;
                        targetItem.statusText = response.data.data.statusText;
                        targetItem.statusClass = response.data.data.statusClass;
                    } else {
                        commonAct.showError(response.data.message);
                    }
                    this.hideLoading();
                }).catch(error => {
                    console.error(error);
                    commonAct.showError(error);
                    this.hideLoading();
                });
            });
        },
    }
}